<template>
  <div>
    <p class="primary--text mt-6">{{$t('CAMPAIGN.FIELD_TITLE')}}</p>
    <v-row class="mt-0">
      <v-col cols="8">
        <v-text-field
          v-model="title"
          @change="$emit('input', {title: $event})"
          required
          :rules="[$rules.required, $rules.maxlength(255)]"
          :placeholder="$t('CAMPAIGN.FIELD_TITLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="channel_categories"
          :items="$t('CAMPAIGN.CHANNEL_CATEGORY_ITEMS')"
          class="text-nowrap"
          item-text="title"
          item-value="category"
          @change="$emit('input', {channel_categories: $event})"
          required
          multiple
          :rules="[$rules.required]"
          :label="$t('CAMPAIGN.FIELD_CHANNEL_CATEGORY')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col
        cols="12"
        md="6"
        sm="6"
        class="pa-0 ma-0"
      >
        <p class="ml-3">
          {{$t('INPUT.DATE_START')}}
          <span
            class="grey--text"
            v-if="start_date"
          >
            {{$moment(start_date).fromNow()}}
          </span>
        </p>
        <v-text-field
          class="ml-4 mr-4"
          dense
          v-model="start_date"
          :rules="[$rules.required]"
          readonly
          outlined
          clearable
        />
        <v-date-picker
          :locale="$i18n.locale"
          color="primary"
          width="100%"
          ref="picker"
          no-title
          flat
          v-model="start_date"
          @change="$emit('input', {start_date: $event})" 
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
        class="pa-0 ma-0"
      >
        <p class="ml-3">
          {{$t('INPUT.DATE_END')}}
          <span
            class="grey--text"
            v-if="end_date"
          >
            {{$moment(end_date).fromNow()}}
          </span>
        </p>
        <v-text-field
          class="ml-4 mr-4"
          dense
          v-model="end_date"
          :label="$t('INPUT.DATE_END')"
          :rules="[$rules.required]"
          readonly
          outlined
          clearable
        />
        <v-date-picker
          :locale="$i18n.locale"
          color="primary"
          width="100%"
          ref="picker"
          no-title
          flat
          v-model="end_date"
          :min="start_date"
          @change="$emit('input', {end_date: $event})"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-checkbox
          class="mt-0 pt-0"
          v-model="ga4_params.define_ga4_params"
          @change="$emit('input', {ga4_params})"
          :label="$t('CAMPAIGN.FIELD_GA4_PARAMS_TITLE')"
          :placeholder="$t('CAMPAIGN.FIELD_GA4_PARAMS_TITLE')"
          outlined
        />
      </v-col>
    </v-row>

    <v-row
      class="mt-0"
      v-if="ga4_params.define_ga4_params"
    >
      <v-col cols="4">
        <v-text-field
          dense
          v-model="ga4_params.ga4_property_id"
          @change="$emit('input', {ga4_params})"
          autofocus
          :rules="[$rules.maxlength(255)]"
          :label="$t('CAMPAIGN.FIELD_GA4_PARAMS_PROPERTY_ID')"
          :placeholder="$t('CAMPAIGN.FIELD_GA4_PARAMS_PROPERTY_ID_PLACEHOLDER')"
          @keyup.enter="false"
          outlined
        />
        <v-text-field
          dense
          v-model="ga4_params.ga4_conversion_event_name"
          @change="$emit('input', {ga4_params})"
          :rules="[$rules.maxlength(255)]"
          :label="$t('CAMPAIGN.FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME')"
          :placeholder="$t('CAMPAIGN.FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME_PLACEHOLDER')"
          @keyup.enter="false"
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name:"FormCampaignCompactTitle",
  data: () => ({
    valid: false,
    ga4_params: {define_ga4_params: false},
    title: undefined,
    channel_categories: undefined,
    start_date: undefined,
    end_date: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({}),
    },
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
    // ga4_params: {
    //   handler (n, o) {
    //     console.log('GA PARAMS CHANGED', n, o, 'emit:', JSON.stringify(n) !== JSON.stringify(o))
    //     if (JSON.stringify(n) !== JSON.stringify(o)) {
    //       this.$emit('input', { ga4_params: this.$clone(this.ga4_params) })
    //     }
    //   },
    //   deep: true,
    // },
    // start_date() {
    //   if(this.start_date) {
    //     this.$emit('input', { start_date: this.start_date })
    //   }
    // },
    // end_date() {
    //   if(this.end_date) {
    //     this.$emit('input', { end_date: this.end_date })
    //   }
    // },
    // ga4_property_id() {
    //   if(this.ga4_property_id) {
    //     this.$emit('input', { ga4_property_id: this.ga4_property_id })
    //   }
    // },
    // ga4_conversion_event_name() {
    //   if(this.ga4_conversion_event_name) {
    //     this.$emit('input', { ga4_conversion_event_name: this.ga4_conversion_event_name })
    //   }
    // },
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        // if ((key === 'ga4_property_id' || key === 'ga4_conversion_event_name') && values[key]) this.ga4_params.define_ga4_params = true
        if (key.includes('_date') && values[key]) values[key] = values[key].slice(0,10)
        this[key] = values[key]
      })
    },
  },
}
</script>
